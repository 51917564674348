import axios from 'axios';

interface NewsletterSubscribeData {
    email: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    consentText: string;
    source: string | null;
}

interface NewsletterOptInData {
    predictedUserId: string;
}

interface NewsletterPersonalizeData {
    predictedUserId: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    birthDay: string;
    birthMonth: string;
    birthYear: string;
    userInterestExperience: boolean;
    userInterestGift: boolean;
}

async function newsletterSubscribe(data: NewsletterSubscribeData): Promise<unknown> {
    const path = '/api/newsletter/subscribe';
    return axios.post(path, data);
}

async function newsletterOptIn(data: NewsletterOptInData): Promise<unknown> {
    const path = '/api/newsletter/opt-in';
    return axios.post(path, data);
}

async function newsletterPersonalize(data: NewsletterPersonalizeData): Promise<unknown> {
    const path = '/api/newsletter/personalize';
    return axios.post(path, data);
}

export { newsletterOptIn, newsletterPersonalize, newsletterSubscribe };
