import { useReducer } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { Button, ButtonColor, ButtonType, CheckboxRadioAlign, Wysiwyg } from '@jsmdg/yoshi';
import {
    NewsletterButtonState,
    NewsletterReducerActionType,
    Salutation,
} from '../../../../shared/enums/newsletter';
import { newsletterReducer } from '../../../reducers/newsletterReducer';
import { newsletterSubscribe } from '../../../services/newsletterSubscribe';
import { FormikCheckbox, FormikInput, FormikSelect, SelectRender } from '../../Formik';
import { SuccessMessage } from '../../SuccessMessage';
import { newsletterConfig } from '../constants';
import { messages } from './i18n';
import styles from './NewsletterSubscribe.module.scss';

type NewsletterSubscribeProps = {
    readonly caption: string;
    readonly emailPlaceholder: string;
    readonly consentText: string;
    readonly buttonLabel: string;
    readonly additionalText: string;
    readonly successText: string;
    readonly email?: string;
    readonly source?: string;
    readonly withNameFields?: boolean;
};

const NewsletterSubscribe = ({
    additionalText,
    buttonLabel,
    caption,
    consentText,
    email = '',
    emailPlaceholder,
    source = '',
    successText,
    withNameFields = false,
}: NewsletterSubscribeProps): JSX.Element => {
    const intl = useIntl();

    const initialValues = {
        newsletterEmail: email,
        consentAccepted: false,
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
    };

    const fields = {
        newsletterEmail: Yup.string()
            .required(intl.formatMessage(messages.validationEmailRequired))
            .email(intl.formatMessage(messages.validationEmailInvalid)),
        consentAccepted: Yup.boolean()
            .required(intl.formatMessage(messages.validationConsentRequired))
            .oneOf([true], intl.formatMessage(messages.validationConsentRequired)),
    };

    const validationSchema = Yup.object().shape(
        withNameFields
            ? {
                  ...fields,
                  salutation: Yup.string().oneOf(Object.values(Salutation)).optional(),
                  firstName: Yup.string().optional(),
                  lastName: Yup.string().optional(),
              }
            : fields,
    );

    const [{ buttonState, confirmationContent, errorText, success }, dispatch] = useReducer(
        newsletterReducer,
        newsletterConfig.initialState,
    );

    const onSubmit = async ({
        firstName,
        lastName,
        newsletterEmail,
        salutation,
    }: {
        newsletterEmail: string;
        salutation?: string;
        firstName?: string;
        lastName?: string;
    }): Promise<void> => {
        dispatch({ type: NewsletterReducerActionType.Loading });

        try {
            await newsletterSubscribe({
                email: newsletterEmail,
                salutation,
                firstName,
                lastName,
                consentText,
                source,
            });

            dispatch({
                type: NewsletterReducerActionType.Success,
                payload: successText.replace(
                    newsletterConfig.userEmailPlaceholder,
                    newsletterEmail,
                ),
            });
            trackAnalyticsEvent({
                eventData: {
                    eventName: GA4EventName.SubscribeNewsletter,
                    feature_category: GA4FeatureCategory.Newsletter,
                },
            });
        } catch {
            dispatch({
                type: NewsletterReducerActionType.Error,
                payload: intl.formatMessage(messages.errorTechnical),
            });
        }
    };

    return success ? (
        <SuccessMessage content={confirmationContent} />
    ) : (
        <div className="g-col-sm-8">
            <h3 className="pb-0 mb-3x">{caption}</h3>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <div className={`grid ${styles.gridMaxContent}`}>
                        <FormikInput
                            name="newsletterEmail"
                            className={classnames({ [styles.input]: !withNameFields })}
                            disabled={buttonState === NewsletterButtonState.Loading}
                            placeholder={emailPlaceholder}
                            errorText={errorText}
                        />

                        {withNameFields && (
                            <div className="mt-3x d-flex gap-3x gap-sm-1x flex-column flex-sm-row">
                                <FormikSelect
                                    label={intl.formatMessage(messages.salutation)}
                                    name="salutation"
                                    options={[
                                        {
                                            label: intl.formatMessage(messages.man),
                                            value: Salutation.Mr,
                                        },
                                        {
                                            label: intl.formatMessage(messages.woman),
                                            value: Salutation.Ms,
                                        },
                                    ]}
                                    as={SelectRender.YoshiDropdown}
                                />
                                <div className="d-flex gap-3x gap-sm-1x flex-column flex-sm-row">
                                    <div>
                                        <FormikInput
                                            placeholder={intl.formatMessage(messages.firstname)}
                                            name="firstName"
                                        />
                                    </div>
                                    <div>
                                        <FormikInput
                                            placeholder={intl.formatMessage(messages.lastname)}
                                            name="lastName"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <FormikCheckbox
                        name="consentAccepted"
                        className="theme-text-body-text mt-3x"
                        label={<Wysiwyg content={consentText} paddingBottom={false} />}
                        textAlign={CheckboxRadioAlign.Top}
                    />
                    <Button
                        className="my-3x flex-grow-1 flex-sm-grow-0"
                        type={ButtonType.Submit}
                        disabled={buttonState === NewsletterButtonState.Loading}
                        color={ButtonColor.Complementary}
                    >
                        <span>{buttonLabel}</span>
                    </Button>
                </Form>
            </Formik>
            <div className={classnames(styles.additionalText)}>
                <Wysiwyg content={additionalText} />
            </div>
        </div>
    );
};

// @loadable/component supports only default export for now.
// eslint-disable-next-line import/no-default-export
export default NewsletterSubscribe;
